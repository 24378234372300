/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap");

:root {
  --primary-color: #0087e2; /* Cool blue tone */
  --tints-color: #ecf7fb; /* Lighter tint */
  --tints-color2: #d6eaf8; /* Secondary tint */
  --shades-color: #2980b9; /* Darker shade */
  --grey-1: #333;
  --grey-2: #555;
  --letter-spacing: 0.75px;
}


body {
  font-family: "Rubik", sans-serif;
  color: var(--grey-2);
  overflow-x: hidden;
}

/* Utility classes for typography */
.heading-primary {
  font-size: 4rem;
  font-weight: 700;
  color: var(--grey-1);
  line-height: 1.05;
  letter-spacing: -0.5px;
  margin-bottom: 3.2rem;
}

.heading-secondary {
  font-size: 2.8rem;
  font-weight: 700;
  color: var(--grey-1);
  margin-bottom: 4.8rem;
}

.heading-tertiary {
  font-size: 2rem;
  font-weight: 700;
  color: var(--grey-1);
  margin-bottom: 2.4rem;
}

.subheading {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--shades-color);
  text-transform: uppercase;
  margin-bottom: 1.6rem;
}

/* Custom components */
.logo {
  height: 8rem;
  margin-top: 30px;
  margin-left: 45px;
}

.logo1 {
  height: 10rem;
  margin-top: -50px;
}

.hero-description {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 4.8rem;
}

.step-number {
  font-size: 8.6rem;
  font-weight: 600;
  color: #ddd;
  margin-bottom: 1.2rem;
}

.step-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.step-img {
  width: 35%;
}

.chef-img {
  width: 400px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 0 auto;
  display: block;
}

.chef-img:hover {
  transform: scale(1.05);
}

.chef-profile {
  text-align: center;
  padding: 20px;
}

.chef-name {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.chef-bio {
  font-size: 0.9rem;
  color: #666;
}

.testimonial-img {
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  margin-bottom: 1.2rem;
}

.testimonial-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 1.6rem;
}

.testimonial-name {
  font-size: 1.6rem;
  color: #6f6f6f;
}

.footer-logo {
  display: block;
  margin-bottom: 3.2rem;
}

.social-links {
  list-style: none;
  display: flex;
  gap: 2.4rem;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.footer-link {
  text-decoration: none;
  font-size: 1.6rem;
  color: #767676;
  transition: all 0.3s;
}

.footer-link:hover {
  color: #555;
}

.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.contacts {
  font-size: 1.6rem;
  line-height: 1.6;
}

.address {
  margin-bottom: 2.4rem;
}


/* 
 */
/**********************
  Google Fonts
/***********************/
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap");

/*****************************/
/* VARIABLES */
/*****************************/

:root {
  --primary-color: #e67e22;
  --tints-color: #fdf2e9;
  --tints-color2: #fae5d3;
  --shades-color: #cf711f;
  --grey-1: #333;
  --grey-2: #555;
  --letter-spacing: 0.75px;
}

/*****************************/
/* COMMON STYLES */
/*****************************/

/* 
---TYPOGRAPHY

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6

- Letter spacing
-0.5px
0.75px

*/

html {
  /* font-size: 10px; */

  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Rubik", sans-serif;
  color: #555;
  line-height: 1;
  font-weight: 400;
  overflow-x: hidden;
}

/*****************************/
/* HEADER SECTION */
/*****************************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9.6rem;
  padding: 0 4.8rem;
  background-color: var(--tints-color);
}

.logo {
  height: 8rem;
  margin-top: 30px;
  margin-left: 45px;
}

.logo1 {
  height: 10rem;
  margin-top: -50px;
}

/*****************************/
/* NAVIGATION SECTION */
/*****************************/

.main-nav-list {
  display: flex;
  list-style: none;
  gap: 1.6rem;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 1.8rem;
  color: #333;
  font-weight: 500;
  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: var(--shades-color);
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  background-color: var(--primary-color);
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: var(--shades-color);
}

.btn-mobile-nav {
  background: none;
  border: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

/**************************/
/* HERO SECTION */
/**************************/

.hero-section {
  background-color: var(--tints-color);
  padding: 4.8rem 0 9.6rem 0;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 9.6rem;
}

.hero-description {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 4.8rem;
}

.hero-img {
  width: 100%;
}

.delivered-meals {
  display: flex;
  margin-top: 8rem;
  align-items: center;
  gap: 1.6rem;
}

.delivered-imgs {
  display: flex;
}

.delivered-imgs img {
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  margin-right: -1.6rem;
  border: 3px solid var(--tints-color);
}

.delivered-imgs img:last-child {
  margin: 0;
}

.delivered-text {
  font-size: 1.8rem;
  font-weight: 600;
}

.delivered-text span {
  font-weight: 700;
  color: var(--shades-color);
}

/**************************/
/* FEATURED IN SECTION */
/**************************/

.section-featured {
  padding: 4.8rem 0 3.2rem 0;
}

.heading-featured-in {
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  margin-bottom: 2.4rem;
  text-align: center;
  color: #888;
}

.logos {
  display: flex;
  justify-content: space-around;
}

.logos img {
  height: 3.2rem;
  filter: brightness(0);
  opacity: 0.5;
}

/**************************/
/* How It Work SECTION */
/**************************/

.section-how {
  padding: 9.6rem 0;
}

.step-number {
  font-size: 8.6rem;
  font-weight: 600;
  color: #ddd;
  margin-bottom: 1.2rem;
}

.step-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.step-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.step-img-box::before,
.step-img-box::after {
  content: "";
  position: absolute;
  display: block;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.step-img-box::before {
  background-color: var(--tints-color);
  width: 60%;

  /* 60% of parents width */
  padding-bottom: 60%;
  z-index: -2;
}

.step-img-box::after {
  width: 45%;
  padding-bottom: 45%;
  background-color: var(--tints-color2);
  z-index: -1;
}

.step-img {
  width: 35%;
}

/**************************/
/* MEALS SECTION */
/**************************/

.section-meals {
  padding: 9.6rem 0;
}

.meal {
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.075);
  border-radius: 11px;
  overflow: hidden;
  transition: all 0.3s;
}

.meal:hover {
  transform: translateY(-1.2rem);
}

.meal-content {
  padding: 3.2rem 4.8rem 4.8rem 4.8rem;
}

.meal-tags {
  margin-bottom: 1.2rem;
  font-weight: 600;
  display: flex;
  gap: 0.4rem;
}

.tag {
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.4rem 0.8rem;
  color: #333;
  text-transform: uppercase;
  border-radius: 100px;
}

.tag--vegetarian {
  background-color: #51cf66;
}

.tag--vegan {
  background-color: #94d82d;
}

.tag--paleo {
  background-color: #ffd43d;
}

.meal-title {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 3.3rem;
}

.meal-attributes {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.meal-attribute {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.meal-icon {
  font-size: 2.4rem;
  color: var(--primary-color);
}

.meal-img {
  width: 100%;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.list-item {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.list-icon {
  font-size: 3rem;
  color: var(--primary-color);
}

.all-recipes {
  font-size: 1.8rem;
  text-align: center;
}

/**************************/
/* TESTIMONIAL SECTION */
/**************************/

.section-testimonials {
  display: grid;
  grid-template-columns: 55fr 45fr;
  align-items: center;
  background-color: var(--tints-color);
}

.testimonials-container {
  padding: 9.6rem;
}

.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4.8rem;
}

.testimonial-img {
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  margin-bottom: 1.2rem;
}

.testimonial-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 1.6rem;
}

.testimonial-name {
  font-size: 1.6rem;
  color: #6f6f6f;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  padding: 1.6rem;
}

.gallery-item {
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  transition: all 0.4s;
}

.gallery-item img:hover {
  transform: scale(1.1);
}

/**************************/
/* Pricing SECTION */
/**************************/

.section-pricing {
  padding: 9.6rem 0;
}

.princing-plan {
  border-radius: 11px;
  width: 75%;
}

.princing-plan--starter {
  justify-self: end;
  border: 2px solid var(--tints-color);
  padding: 4.6rem;
}

.princing-plan--complete {
  background-color: var(--tints-color);
  padding: 4.8rem;
  position: relative;
  overflow: hidden;
}

.princing-plan--complete::after {
  content: "Best Value";
  position: absolute;
  top: 6%;
  right: -18%;
  background-color: #ffd43d;
  font-size: 1.4rem;
  font-weight: 700;
  color: #333;
  padding: 0.8rem 8rem;
  transform: rotate(45deg);
}

.plan-header {
  text-align: center;
  margin-bottom: 4.8rem;
}

.plan-name {
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.75;
  color: var(--shades-color);
  margin-bottom: 3.2rem;
}

.plan-price {
  font-size: 6.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.6rem;
}

.plan-price span {
  /* font-size: 6rem;
  font-weight: 500; */
  margin-bottom: 0.8rem;
}

.plan-text {
  font-size: 1.6rem;
  line-height: 1.6;
  color: #676767;
}

.plan-sing-up {
  text-align: center;
  margin-top: 4.8rem;
}

.plan-details {
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: center;
}

.feature-icon {
  color: #e67e22;
  height: 3.2rem;
  width: 3.2rem;
  background-color: #fdf2e9;
  margin-bottom: 3.2rem;
  padding: 1.6rem;
  border-radius: 50%;
}

.feature-title {
  font-size: 2.4rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 1.6rem;
}

.feature-text {
  font-size: 1.8rem;
  line-height: 1.8;
}

/**************************/
/* CTA SECTION */
/**************************/

.section-cta {
  /* top / right / bottom / left */
  /* padding: 9.6rem 0 12.8rem 0; */

  /* top / horizontal / left */
  padding: 4.8rem 0 12.8rem;
}

.cta {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* background-color: #e67e22; */
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;

  background-image: linear-gradient(to right bottom, #eb984e, #e67e22);
  overflow: hidden;
}

.cta-text-box {
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
  color: #45260a;
}

.cta .heading-secondary {
  /* color: #45260a; */
  color: inherit;
  margin-bottom: 3.2rem;
}

.cta-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 4.8rem;
}

.cta-img-box {
  background-image: linear-gradient(to right bottom,
      rgba(235, 151, 78, 0.35),
      rgba(230, 125, 34, 0.35)),
    url("./assets/img/eating.jpg");
  background-size: cover;
  background-position: center;
}

.cta-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 2.4rem;
}

.cta-form label {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.cta-form input,
.cta-form select {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: #fdf2e9;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.cta-form input::placeholder {
  color: #aaa;
}

.cta *:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem rgba(253, 242, 233, 0.5);
}

/**************************/
/* FOOTER */
/**************************/

.footer {
  padding: 1.8rem 0;
  border-top: 1px solid #eee;
}

.grid--footer {
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
}

.logo-col {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  display: block;
  margin-bottom: 1rem;
}

.social-links {
  list-style: none;
  display: flex;
  gap: 2.4rem;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.copyright {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #767676;
  margin-top: auto;
}

.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.contacts {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6;
}

.address {
  margin-bottom: 2.4rem;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1.6rem;
  color: #767676;
  transition: all 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #555;
}


.chef-img {
  width: 400px;
  /* Increased width */
  height: 300px;
  /* Increased height */
  object-fit: cover;
  /* Ensure the image covers the container without distortion */
  border-radius: 50%;
  /* Make the images round */
  border: 3px solid #ddd;
  /* Add a border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow */
  transition: transform 0.3s ease;
  /* Smooth transition on hover */
  margin: 0 auto;
  /* Center the image */
  display: block;
  /* Ensure the image is a block element */
}

.chef-img:hover {
  transform: scale(1.05);
  /* Slightly enlarge the image on hover */
}

.chef-profile {
  text-align: center;
  /* Center align the text */
  padding: 20px;
  /* Add padding around each profile */
}

.chef-name {
  margin-top: 5%;

}



/* rem and em do NOT depend on html font-size in media queries! Instead, 1rem = 1em = 16px */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 4.4rem;
  }

  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  html {
    /* 9px / 16px  */
    font-size: 56.25%;
  }

  .grid {
    column-gap: 4.8rem;
    row-gap: 6.4rem;
  }

  .heading-secondary {
    font-size: 3.6rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }

  .header {
    padding: 0 3.2rem;
  }

  .main-nav-list {
    gap: 6.2rem;
  }

  .hero {
    gap: 4.8rem;
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem;
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  html {
    /* 8px / 16px = 0.5 = 50% */
    font-size: 50%;
  }

  .hero {
    grid-template-columns: 1fr;
    padding: 0 8rem;
    gap: 6.4rem;
  }

  .hero-text-box,
  .hero-img-box {
    text-align: center;
  }

  .hero-img {
    width: 60%;
  }

  .delivered-meals {
    justify-content: center;
    margin-top: 3.2rem;
  }

  .logos img {
    height: 2.4rem;
  }

  .step-number {
    font-size: 7.4rem;
  }

  .meal-content {
    padding: 2.4rem 3.2rem 3.2rem 3.2rem;
  }

  .section-testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(6, 1fr);
  }

  .cta {
    /* 3/5 = 60% + 2/5 = 40% */
    grid-template-columns: 3fr 2fr;
  }

  .cta-form {
    grid-template-columns: 1fr;
  }

  .btn--form {
    margin-top: 1.2rem;
  }

  /* MOBILE NAVIGATION */
  @media (max-width: 44em) {
    .btn-mobile-nav {
      display: block;
    }

    .main-nav {
      background-color: rgba(255, 255, 255, 0.97);
      position: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      transform: translateX(100%);

      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in;

      /* Hide navigation */
      /* Allows NO transitions at all */
      /* display: none; */

      /* 1) Hide it visually */
      opacity: 0;

      /* 2) Make it unaccessible to mouse and keyboard */
      pointer-events: none;

      /* 3) Hide it from screen readers */
      visibility: hidden;
    }

    .nav-open .main-nav {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      transform: translateX(0);
    }

    .nav-open .icon-mobile-nav[name="close-outline"] {
      display: block;
    }

    .nav-open .icon-mobile-nav[name="menu-outline"] {
      display: none;
    }

    .main-nav-list {
      flex-direction: column;
      gap: 4.8rem;
    }

    .main-nav-link:link,
    .main-nav-link:visited {
      font-size: 3rem;
    }
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {

  .grid-cols-3,
  .grid-cols-3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .diets {
    grid-column: 1 / -1;
    justify-self: center;
  }

  .heading-secondary {
    margin-bottom: 4.8rem;
  }

  .pricing-plan {
    width: 100%;
  }

  .grid--footer {
    grid-template-columns: repeat(6, 1fr);
  }

  .logo-col,
  .address-col {
    grid-column: span 1;
  }

  .nav-col {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 3.2rem;
  }
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .grid {
    row-gap: 4.8rem;
  }

  .grid-cols-2,
  .grid-cols-3,
  .grid-cols-4 {
    grid-template-columns: 1fr;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 2.4rem 1.6rem;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem 0;
  }

  .hero {
    padding: 0 3.2rem;
  }

  .hero-img {
    width: 80%;
  }

  .logos img {
    height: 1.2rem;
  }

  .step-img-box:nth-child(2) {
    grid-row: 1;
  }

  .step-img-box:nth-child(6) {
    grid-row: 5;
  }

  .step-img-box {
    transform: translateY(2.4rem);
  }

  .testimonials {
    grid-template-columns: 1fr;
  }

  .princing-plan--starter {
    justify-self: start;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
  }

  .cta {
    grid-template-columns: 1fr;
  }

  .cta-text-box {
    padding: 3.2rem;
  }

  .cta-img-box {
    height: 32rem;
    grid-row: 1;
  }
}

/*****************************/
/* GENERAL REUSABLE COMPONENTS */
/*****************************/

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 9.6rem;
}

.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-y-center {
  align-items: center;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-size: 5.2rem;
  font-weight: 700;
  color: #333;
}

.heading-primary {
  line-height: 1.05;
  letter-spacing: -0.5px;
  margin-bottom: 3.2rem;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}

.subheading {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--shades-color);
  margin-bottom: 1.6rem;
  text-transform: uppercase;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.btn:link,
.btn:visited {
  padding: 1.6rem 3.2rem;
  border-radius: 0.9rem;
  font-size: 2rem;
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s;
  font-weight: 600;
}

.btn--full:link,
.btn--full:visited {
  background-color: var(--primary-color);
  color: #fff;
}

.btn--full:hover,
.btn--full:active {
  background-color: var(--shades-color);
}

.btn--outline:link,
.btn--outline:visited {
  background-color: #fff;
  color: #555;
}

.btn--outline:hover,
.btn--outline:active {
  background-color: var(--tints-color);
  box-shadow: inset 0 0 0 3px #fff;
}

.btn--form {
  background-color: #45260a;
  color: #fdf2e9;
  align-self: end;
  padding: 1.2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.btn--form:hover {
  background-color: #fff;
  color: #555;
}

/*****************************/
/* CUSTOM REUSABLE CLASS */
/*****************************/

.link:link,
.link:visited {
  display: inline-block;
  color: var(--primary-color);
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  transition: all 0.3s;
}

.link:hover,
.link:active {
  color: var(--shades-color);
  border-bottom: 1px solid transparent;
}

.centered {
  text-align: center;
}

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.margin-bottom-md {
  margin-bottom: 4.8rem !important;
}

strong {
  font-weight: 500;
}

.section-meals {
  padding: 4rem 0;
}

.centered {
  text-align: center;
}

.subheading {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #cf711f;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.heading-secondary {
  font-size: 2.4rem;
  line-height: 1.2;
  margin-bottom: 3rem;
}

.grid {
  display: grid;
  gap: 3rem;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.margin-bottom-md {
  margin-bottom: 4rem;
}

.chef-img {
  width: 100%;
  max-width: 300px;
  height: 80%;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.chef-name {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.chef-bio {
  font-size: 1.0rem;
  color: #666;
}



/* How It Works */
@keyframes endless-wave {
  0% {
    transform: translateY(0)
  }

  to {
    transform: translateY(-245px)
  }
}

.animate-endless-wave {
  animation: endless-wave 20s linear infinite
}

.will-change-transform {
  will-change: transform
}

@keyframes pulseLoop {
  0% {
    opacity: 0;
    transform: scale(.25) translateZ(0)
  }

  30% {
    opacity: .4
  }

  70% {
    opacity: 0
  }

  80% {
    transform: scale(1) translateZ(0)
  }
}

.pulse[data-v-452fae4b] {
  opacity: 0;
  transform-origin: center;
  animation: pulseLoop 12s linear infinite
}

.pulse-1[data-v-452fae4b] {
  animation-delay: -4s
}

.pulse-2[data-v-452fae4b] {
  animation-delay: -8s
}

/* full-circle */

@keyframes full-circle-2 {

  0%,
  10% {
    opacity: 0;
  }

  10%,
  20% {
    opacity: 1;
  }

  30%,
  40% {
    opacity: 1;
  }

  40%,
  50% {
    opacity: 1;
  }

  50%,
  60% {
    opacity: 1;
  }

  70%,
  80% {
    opacity: 1;
  }

  80%,
  90% {
    opacity: 1;
  }

  90%,
  95% {
    opacity: 1;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@keyframes full-circle-3 {

  0%,
  10% {
    opacity: 0;
  }

  10%,
  20% {
    opacity: 0;
  }

  30%,
  40% {
    opacity: 1;
  }

  40%,
  50% {
    opacity: 1;
  }

  50%,
  60% {
    opacity: 1;
  }

  70%,
  80% {
    opacity: 1;
  }

  80%,
  90% {
    opacity: 1;
  }

  90%,
  95% {
    opacity: 0;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@keyframes full-circle-4 {

  0%,
  10% {
    opacity: 0;
  }

  10%,
  20% {
    opacity: 0;
  }

  30%,
  40% {
    opacity: 0;
  }

  40%,
  50% {
    opacity: 1;
  }

  50%,
  60% {
    opacity: 1;
  }

  70%,
  80% {
    opacity: 1;
  }

  80%,
  90% {
    opacity: 0;
  }

  90%,
  95% {
    opacity: 0;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@keyframes full-circle-5 {

  0%,
  10% {
    opacity: 0;
  }

  10%,
  20% {
    opacity: 0;
  }

  30%,
  40% {
    opacity: 0;
  }

  40%,
  50% {
    opacity: 0;
  }

  50%,
  60% {
    opacity: 1;
  }

  70%,
  80% {
    opacity: 0;
  }

  80%,
  90% {
    opacity: 0;
  }

  90%,
  95% {
    opacity: 0;
  }

  95%,
  100% {
    opacity: 0;
  }
}

.full-circle2 {
  animation: full-circle-2 5s 3s ease-in-out infinite;
  rotate: 1 0 0 180deg;
  opacity: 0;
}

.full-circle3 {
  animation: full-circle-3 5s 3s ease-in-out infinite;
  rotate: 1 0 0 180deg;
  opacity: 0;
}

.full-circle4 {
  animation: full-circle-4 5s 3s ease-in-out infinite;
  rotate: 1 0 0 180deg;
  opacity: 0;
}

.full-circle5 {
  animation: full-circle-5 5s 3s ease-in-out infinite;
  rotate: 1 0 0 180deg;
  opacity: 0;
}